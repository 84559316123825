const [d] = [document];

const domReady = () => {
    $(document).ready(function () {
        // iframe要素の初期化
        var iframe = $('<iframe>', {
            width: '1920',
            height: '1080',
            frameborder: '0',
            allow: 'autoplay; fullscreen; picture-in-picture',
        }).appendTo('#player');

        // 最初のli要素からビデオ情報を取得してプレーヤーを設定
        var firstLi = $('#carousel li:first');
        var firstVideoId = firstLi.data('video-id');
        var firstVideoTitle = firstLi.data('video-title');
        updatePlayer(firstVideoId, firstVideoTitle);

        // 最初のli要素にクラスを追加
        firstLi.addClass('js-active');

        // 他のli要素をクリックした時のイベント
        $('#carousel li').click(function () {
            // クリックされたli要素からビデオ情報を取得
            var videoId = $(this).data('video-id');
            var videoTitle = $(this).data('video-title');

            // プレーヤーを更新
            updatePlayer(videoId, videoTitle);

            // アクティブクラスの更新
            $('#carousel li').removeClass('js-active');
            $(this).addClass('js-active');
            $('.block__movie__modal').addClass('js-active');
        });

        $('.block__movie__modal button').click(function () {
            $('.block__movie__modal').removeClass('js-active');

            // プレーヤーを停止する
            // iframeのsrcを空にしてから、再度設定することでプレーヤーをリセットする
            var currentSrc = iframe.attr('src');
            iframe.attr('src', ''); // プレーヤーを一旦停止
            iframe.attr('src', currentSrc); // 必要に応じて、元のビデオに戻す
        });

        // プレーヤーとタイトルを更新する関数
        function updatePlayer(videoId, videoTitle) {
            iframe.attr(
                'src',
                'https://player.vimeo.com/video/' +
                    videoId +
                    '?badge=0&amp;autopause=0&amp;quality_selector=1&amp;player_id=0&amp;app_id=58479'
            );
            iframe.attr('video-title', videoTitle);

            // タイトルを更新
            $('.block__movie__text').text(videoTitle);
        }
    });
};

export default function MOVIE() {
    d.addEventListener('DOMContentLoaded', domReady());
}
