import Swiper from 'swiper/bundle';

const [d] = [document];

class Blocks {
    mainvisual() {
        //メインスライダー
        // new Swiper('.mainvisual-slider[data-plugin="mainSlider"]', {
        //     slidesPerView: 'auto',
        //     loop: true,
        //     autoplay: {
        //         delay: 5000,
        //         disableOnInteraction: false,
        //     },
        //     pagination: {
        //         el: '.mainvisual .swiper-pagination',
        //         clickable: true,
        //     },
        //     navigation: {
        //         nextEl: '.mainvisual .swiper-button-next',
        //         prevEl: '.mainvisual .swiper-button-prev',
        //     },
        //     breakpoints: {
        //         576: {
        //             centeredSlides: true,
        //             spaceBetween: 35,
        //         },
        //     },
        // });

        const thumb = document.querySelectorAll('.thumbSlider[data-plugin="thumbSlider"] .thumb-media');

        const switchThumb = (index) => {
            document
                .querySelector('.thumbSlider .thumb-media-active')
                .classList.remove('thumb-media-active');
            thumb[index].classList.add('thumb-media-active');
        };

        const mySwiper = new Swiper('.mainvisual-slider[data-plugin="mainSlider"]', {
            // effect: 'fade',
            // fadeEffect: {
            //     crossFade: true,
            // },
            loop: true,
            loopAdditionalSlides: 1,
            speed: 500,
            navigation: {
                nextEl: '.mainvisual-slider .swiper-button-next',
                prevEl: '.mainvisual-slider .swiper-button-prev',
            },
            pagination: {
                el: '.mainvisual .swiper-pagination',
                clickable: true,
            },
            on: {
                afterInit: (swiper) => {
                    thumb[swiper.realIndex].classList.add('thumb-media-active');
                    for (let i = 0; i < thumb.length; i++) {
                        thumb[i].onclick = () => {
                            swiper.slideTo(i);
                        };
                    }
                },
                slideChange: (swiper) => {
                    switchThumb(swiper.realIndex);
                },
            },
        });
    }
}

const domReady = (TPL) => {
    let blocks = new Blocks();
    window.addEventListener('load', () => {
        blocks.mainvisual();
    });
};

const jqInit = () => false;

export default function HOME() {
    // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
    d.addEventListener('DOMContentLoaded', domReady());
    $(() => jqInit());
}
