export default class COMMON
{
    init()
    {

        //page top animation
        const $header = $('.l-header');
        // const $totop_btn = $('.l-footer__totop');
        // $totop_btn.hide();
        $(window).on('scroll', function () {
            if ($(this).scrollTop() > 100) {
                // $totop_btn.addClass('js-scroll');
                $header.addClass('js-scroll');
            } else
            {
                // $totop_btn.removeClass('js-scroll');
                $header.removeClass('js-scroll');
            }
        });

        // 交差を検知したい要素
        const lazydisp = document.querySelectorAll('[data-lazydisp]');
        // IntersectionObserverのオプションを設定
        const options = {
            root: null,
            rootMargin: "20% 0px -20%",
            threshold: 0 // 閾値は0
        };

        // IntersectionObserverを呼び出し
        const observer = new IntersectionObserver(showElement,options);

        // lazydispクラスが付与されてる要素にたどり着いたらIntersectionObserverを実行
        lazydisp.forEach((elm) =>
        {
            elm.classList.add('lazyhide');
            observer.observe(elm);
        });

        // 要素が表示されたら実行する動作
        function showElement(entries) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
            // 要素にactiveクラスを付与
            entry.target.classList.remove('lazyhide');
            }
        });
        }
    }
}